import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { LanguageLocale } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
	ContentSection,
	Flex,
	FlexItem,
	Grid,
	Row,
	Column,
	SplitConfig,
} from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { VideoWrapperProps } from "@components/Helper/common";
import { getVideoPadding } from "@components/Helper/commonStyles";
import { Video } from "@ryerson/frontend.media";
import { Icon } from "@ryerson/frontend.assets";
import { Link, ButtonLink } from "@ryerson/frontend.navigation";
import ContactUsRefactor from "@components/Shared/ContactUsRefactor/ContactUs";
import { renderCompanyProductsServicesRichText } from "@components/Shared/model/Contentful";
import { Accordion, AccordionTab } from "@ryerson/frontend.expansion";
import Map from "@components/Locations/Map/Map";
import { FormatPhoneNumber } from "@components/Locations/Locations";
import { states } from "@components/Shared/YourMetalExperts/YourMetalExperts";

export type StaticCompanyContent = {
	certifications: string;
	our: string;
	products: string;
	and: string;
	services: string;
	industriesWeServe: string;
	industriesWeServeBlurb: string;
	locations: string;
	locationViewDetails: string;
};

export type FoCCertification = {
	displayName: string;
	downloadableFile: {
		file: {
			url: string;
		};
	};
};
export type FoCIndustryServed = {
	title: string;
	blurb: {
		blurb: string;
	};
	image: {
		file: {
			url: string;
		};
	};
};

export type FoCProductService = {
	productOrService: string;
	productServiceTitle: string;
	productServiceImage: {
		file: {
			url: string;
		};
	};
	productServiceList: {
		raw: string;
	};
};

export type FoCLocation = {
	slug: string;
	locationName: string;
	streetAddress: string;
	city: string;
	state: string;
	zipCode: string;
	country: string;
	phoneNumber: string;
	locationHours: {
		mondayOpen: string;
		mondayClose: string;
		tuesdayClose: string;
		tuesdayOpen: string;
		wednesdayClose: string;
		wednesdayOpen: string;
		thursdayOpen: string;
		thursdayClose: string;
		fridayClose: string;
		fridayOpen: string;
		saturdayOpen: string;
		saturdayClose: string;
		sundayClose: string;
		sundayOpen: string;
	};
	locationPicture: {
		file: {
			url: string;
		};
	};
	location: {
		lat: number;
		lon: number;
	};
};

export type FoCCompanyContent = {
	id: string;
	companyName: string;
	slug: string;
	logo: {
		file: {
			url: string;
		};
	};
	mainFoCImage: {
		file: {
			url: string;
		};
	};
	mainFoCBlurb: string;
	heroSubheader: {
		heroSubheader: string;
	};
	heroBody: {
		heroBody: string;
	};
	heroImage: {
		file: {
			url: string;
		};
	};
	heroVideo?: string;
	companyType: {
		specialtyTitle: string;
		slug: string;
	};
	certifications?: FoCCertification[];
	productsServices: FoCProductService[];
	industriesServed?: FoCIndustryServed[];
	locations: FoCLocation[];
	node_locale: "en-US" | "fr-CA" | "es-MX";
};

export type ContentfulNode = {
	node: FoCCompanyContent;
};

export type CompanyProps = {
	contentfulContent: ContentfulNode[];
	content: StaticCompanyContent;
};

const Hero = styled.div`
	width: 100%;
	height: auto;
	position: relative;
	display: block;
`;

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
				margin-bottom: 17px;
			}
		`;
	}}
`;

const Divider = styled.div`
	width: 100%;
	opacity: 0.1;
	margin-top: 30px;
	margin-bottom: 42px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.white};
		`;
	}}
`;

const DividerSecondary = styled.div`
	width: 100%;
	opacity: 0.1;
	margin-top: 30px;
	margin-bottom: 42px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.gray};
		`;
	}}
`;

const TextWrapper = styled.div`
	max-width: 460px;
`;

const VideoWrapper = styled.div`
	${(props: VideoWrapperProps) => {
		const { theme, buttonOrientation = "right", buttonSize = "md" } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				aspect-ratio: 3/2.5;
				max-width: 100%;
				${buttonOrientation === "center"
					? 0
					: getVideoPadding(buttonOrientation, true, buttonSize)};
			}
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				max-width: 560px;
				height: 360px;
			}
			@media only screen and (max-width: ${theme.breakpoints.xl}) {
				max-width: ${buttonOrientation && buttonOrientation === "right"
					? "calc(100% - 40px)"
					: "560px"};
			}
		`;
	}}
`;

const ImageWrapper = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				max-width: 560px;
				height: 360px;
				margin-top: 22px;
			}
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
			}
		`;
	}}
`;

const LogoWrapper = styled.div`
    max-height: 90px;
    max-width: 200px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 0px;
				width: 70px;
				margin-top: 35px;
			}
		`;
	}}
`;

const CertificationsBox = styled.div`
	padding: 20px;
	margin-top: 35px;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.white};
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				width: 360px;
			}
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				padding: 20px 10px;
                margin-bottom: 15px;
			}
		`;
	}}
`;

const PdfIcon = styled(Icon)`
	display: inline-block;
	margin-left: 10px;
	vertical-align: middle;
`;

const ProductServiceContainer = styled.div`
	width: 100%;
	height: 100%;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.white};
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				border-bottom: 1px solid ${theme.colors.primary.lighterGray};
				padding: 10px;
				margin-bottom: 20px;
			}
		`;
	}}
`;

const Spacer = styled.div`
	margin-top: 35px;
`;

const HoursDaysSection = styled.div`
	display: inline-block;
	width: 24%;
`;

const HoursHoursSection = styled.div`
	display: inline-block;
	width: 75%;
`;

const Company: React.FC<CompanyProps> = ({ contentfulContent, content }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();

	const imageStyles = css`
		width: 100%;
        height: 100%;
		@media only screen and (max-width: ${theme.breakpoints.lg}) {
			height: 192px;
			object-fit: cover;
		}
	`;

	const logoStyles = css`
		mix-blend-mode: lighten;
		opacity: 0.8;
        max-height: 90px;
        max-width: 200px;
		@media only screen and (max-width: ${theme.breakpoints.lg}) {
			width: 100px;
			margin-top: 20px;
		}
	`;

	const productServiceImageStyles = css`
		width: 80px;
		height: 80px;
		margin-top: 35px;
		margin-right: 20px;
		@media only screen and (max-width: ${theme.breakpoints.lg}) {
			width: 60px;
			height: 60px;
			margin-top: 0px;
			margin-right: 10px;
		}
	`;

	const verticalSplit: SplitConfig = {
		splitDirection: "vertical",
		splitColors: [
			{
				color: theme.colors.primary.secondaryBrand,
				percentage: "60%",
			},
			{
				color: theme.colors.secondary.background,
				percentage: "40%",
			},
		],
	};

	const [languageContent, setLanguageContent] = React.useState<FoCCompanyContent>(
		contentfulContent[0].node
	);
	React.useEffect(() => {
		contentfulContent?.forEach((data: ContentfulNode) => {
			if (LanguageLocale[data.node?.node_locale] === language) setLanguageContent(data.node);
		});
	}, [language]);

	let productsServicesTitle = "";
	let productsExists = false;
	let servicesExists = false;
	languageContent.productsServices.forEach((productService) => {
		if (productService.productOrService === "Product") {
			productsExists = true;
		} else if (productService.productOrService === "Service") {
			servicesExists = true;
		}
	});
	if (productsExists && servicesExists) {
		productsServicesTitle =
			content.our + " " + content.products + " " + content.and + " " + content.services;
	} else if (productsExists) {
		productsServicesTitle = content.our + " " + content.products;
	} else if (servicesExists) {
		productsServicesTitle = content.our + " " + content.services;
	}

	const formatState = (country: string, stateName: string) => {
		let statesObj = states[country].find((obj: any) => obj.label === stateName);
		return statesObj.id;
	};

	const heroImageVideoContent = (heroImageUrl: string, heroVideoId?: string) => {
		if (heroVideoId) {
			return (
				<>
					<Media greaterThanOrEqual="lg">
						<VideoWrapper theme={theme} buttonOrientation="right">
							<Video
								videoId={heroVideoId}
								imageUrl={heroImageUrl}
								buttonOrientation="right"
							></Video>
						</VideoWrapper>
					</Media>
					<Media lessThan="lg">
						<VideoWrapper theme={theme} buttonOrientation="top-right">
							<Video
								videoId={heroVideoId}
								buttonOrientation="top-right"
								imageUrl={heroImageUrl}
							></Video>
						</VideoWrapper>
					</Media>
				</>
			);
		} else {
			return (
				<>
					<ImageWrapper theme={theme}>
						<img src={heroImageUrl} css={imageStyles} />
					</ImageWrapper>
				</>
			);
		}
	};

	const ProductServiceContent = (productService: FoCProductService) => {
		return (
			<>
				<Media
					greaterThanOrEqual="lg"
					css={css`
						height: 100%;
					`}
				>
					<ProductServiceContainer theme={theme}>
						<Flex justifyContent="space-between">
							<FlexItem
								css={css`
									margin-left: 30px;
									margin-top: 50px;
									width: 40%;
								`}
							>
								<Typography size="lg" color={theme.colors.secondary.header}>
									{productService.productServiceTitle}
								</Typography>
							</FlexItem>
							<FlexItem>
								<img
									src={productService.productServiceImage.file.url}
									css={productServiceImageStyles}
								/>
							</FlexItem>
						</Flex>
						<div
							css={css`
								margin-left: 30px;
                                margin-right: 30px;
								padding-bottom: 30px;
							`}
						>
							<Typography variant="div" size="md">
								{renderCompanyProductsServicesRichText(
									productService.productServiceList.raw,
									"primary"
								)}
							</Typography>
						</div>
					</ProductServiceContainer>
				</Media>
				<Media lessThan="lg">
					<ProductServiceContainer theme={theme}>
						<Flex justifyContent="space-between">
							<FlexItem>
								<img
									src={productService.productServiceImage.file.url}
									css={productServiceImageStyles}
								/>
							</FlexItem>
							<FlexItem css={css`
                                align-self:center; 
                                width: calc(100% - 80px);
                                text-align: center;`
                            }>
								<Typography size="lg" color={theme.colors.secondary.header}>
									{productService.productServiceTitle}
								</Typography>
							</FlexItem>
						</Flex>
                        <div css={css``}>
                            {renderCompanyProductsServicesRichText(
                                productService.productServiceList.raw,
                                "primary"
                            )}
                        </div>
					</ProductServiceContainer>
				</Media>
			</>
		);
	};


	return (
		<>
			<Hero>
                {/* HERO DESKTOP */}
				<Media greaterThanOrEqual="lg">
					<ContentSection type="tertiary">
						<BreadcrumbContainer theme={theme}>
							<Breadcrumb type="tertiary" size="xs" />
						</BreadcrumbContainer>
						<Flex justifyContent="space-between">
							<FlexItem>
								<Typography
									variant="h1"
									color={theme.colors.primary.white}
									css={css`
										font-size: 75px;
										margin-top: 27px;
                                        margin-bottom: 20px;
									`}
								>
									{languageContent.companyName}
								</Typography>
							</FlexItem>
							<FlexItem css={css`align-content: end;`}>
								<LogoWrapper theme={theme}>
									<img src={languageContent.logo.file.url} css={logoStyles} />
								</LogoWrapper>
							</FlexItem>
						</Flex>
						<Divider theme={theme} />
						<Grid>
							<Row>
								<Column lg={6}>
									<TextWrapper>
										<Typography variant="p" type="tertiary" size="lg">
											{languageContent.heroSubheader.heroSubheader}
										</Typography>
										<Typography
											variant="p"
											type="tertiary"
											size="md"
											css={css`
												white-space: pre-line;
											`}
										>
											{languageContent.heroBody.heroBody}
										</Typography>
									</TextWrapper>
								</Column>
								<Column lg={6}>
									{heroImageVideoContent(
										languageContent.heroImage.file.url,
										languageContent?.heroVideo
									)}
								</Column>
							</Row>
						</Grid>
					</ContentSection>
				</Media>
                {/* HERO MOBILE */}
				<Media lessThan="lg">
					<ContentSection type="tertiary">
						<BreadcrumbContainer theme={theme}>
							<Breadcrumb type="tertiary" size="xs" />
						</BreadcrumbContainer>

						<Typography variant="h1" color={theme.colors.primary.white}>
							{languageContent.companyName}
						</Typography>
						<img src={languageContent.logo.file.url} css={logoStyles} />

						<Typography variant="p" color={theme.colors.primary.white} size="xl">
							{languageContent.heroSubheader.heroSubheader}
						</Typography>
						{heroImageVideoContent(
							languageContent.heroImage.file.url,
							languageContent?.heroVideo
						)}
						<Typography
							variant="p"
							color={theme.colors.primary.white}
							size="md"
							css={css`
								white-space: pre-line;
							`}
						>
							{languageContent.heroBody.heroBody}
						</Typography>
					</ContentSection>
				</Media>
			</Hero>
			{/* CERTIFICATIONS*/}
			{languageContent.certifications && languageContent.certifications.length > 0 ? (
				<>
                    {/* CERTIFICATIONS DESKTOP*/}
					<Media greaterThanOrEqual="lg">
						<ContentSection type="split" split={verticalSplit}>
							<Typography
								size="lg"
								color={theme.colors.primary.white}
								css={css`
									margin-bottom: 20px;
								`}
							>
								{content.certifications}
							</Typography>
							<CertificationsBox theme={theme}>
								<ul>
									{languageContent.certifications.map((certification, index) => {
										return (
											<li
												css={css`
													margin-bottom: 5px;
												`}
												key={index}
											>
												<Link
													color={theme.colors.primary.secondaryBrand}
													target="_blank"
													to={certification.downloadableFile.file.url}
													size="sm"
												>
													{certification.displayName}
													<PdfIcon
														color={theme.colors.primary.primaryBrand}
														icon="file-pdf"
														size="sm"
													/>
												</Link>
											</li>
										);
									})}
								</ul>
							</CertificationsBox>
						</ContentSection>
					</Media>
                    {/* CERTIFICATIONS MOBILE*/}
					<Media lessThan="lg">
						<ContentSection type="tertiary">
							<Typography size="xl" color={theme.colors.primary.white}>
								{content.certifications}
							</Typography>
							<CertificationsBox theme={theme}>
								<ul>
									{languageContent.certifications.map((certification, index) => {
										return (
											<div key={index}>
												<li
													css={css`
														margin-bottom: 5px;
													`}
												>
													<Link
														gatsby={false}
														color={theme.colors.primary.secondaryBrand}
														target="_blank"
														to={certification.downloadableFile.file.url}
														size="sm"
													>
														{certification.displayName}
														<PdfIcon
															color={
																theme.colors.primary.primaryBrand
															}
															icon="file-pdf"
															size="sm"
														/>
													</Link>
												</li>
											</div>
										);
									})}
								</ul>
							</CertificationsBox>
						</ContentSection>
					</Media>
				</>
			) : (
                <Media greaterThanOrEqual="lg">
                    <ContentSection type="tertiary">
                        <></>
                    </ContentSection>
                </Media>
			)}
			{/* PRODUCT SERVICES DESKTOP */}
			<Media greaterThanOrEqual="lg">
				<ContentSection type="secondary">
					<Typography
						variant="h1"
						color={theme.colors.secondary.header}
						css={css`
							font-size: 75px;
							margin-top: 27px;
						`}
					>
						{productsServicesTitle}
					</Typography>
					<div css={css`
                        width: 100%;
                        opacity: 0.1;
                        margin-top: 50px;
                        margin-bottom: 50px;
                        border-bottom: 1px solid ${theme.colors.primary.gray};
                    `}>
                    </div>
					<Grid>
						<Row>
							{languageContent.productsServices.map((productService, index) => {
								return (
									<Column lg={4} gutter={10} key={index}>
										{ProductServiceContent(productService)}
									</Column>
								);
							})}
						</Row>
					</Grid>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection>
					<Accordion multiple={true}>
						{/* PRODUCT SERVICES MOBILE */}
						<AccordionTab header={productsServicesTitle} circled={true} multiple={true} maxHeight="10000px">
							<Spacer />
							{languageContent.productsServices.map((productService, index) => {
								return (
									<div key={index}>{ProductServiceContent(productService)}</div>
								);
							})}
						</AccordionTab>
						{/* INDUSTRIES MOBILE */}
						{languageContent.industriesServed &&
						languageContent.industriesServed.length > 0 ? (
							<AccordionTab
								header={content.industriesWeServe}
								circled={true}
								multiple={true}
                                maxHeight="10000px"
							>
								<Typography
									variant="p"
									color={theme.colors.secondary.header}
									size="md"
								>
									{content.industriesWeServeBlurb}
								</Typography>
								{languageContent.industriesServed.map((industry, index) => {
									return (
										<div
											key={index}
											css={css`
												margin-top: 40px;
											`}
										>
											<Flex alignItems="center">
												<FlexItem>
													<Typography
														variant="p"
														size="xl"
														color={theme.colors.primary.header}
														css={css`
															margin-top: 0px;
														`}
													>
														{industry.title}
													</Typography>
													<Typography
														variant="p"
														size="md"
														color={theme.colors.primary.text}
													>
														{industry.blurb.blurb}
													</Typography>
												</FlexItem>
												<FlexItem>
													<div
														css={css`
															width: 100px;
															height: 170px;
															margin-left: 40px;
															background-image: url(${industry.image
																.file.url});
															background-size: cover;
															background-repeat: no-repeat;
															background-position: center;
														`}
													></div>
												</FlexItem>
											</Flex>
										</div>
									);
								})}
							</AccordionTab>
						) : (
							<></>
						)}
					</Accordion>
				</ContentSection>
			</Media>
			{/* INDUSTRIES DESKTOP */}
			{languageContent.industriesServed && languageContent.industriesServed.length > 0 ? (
				<>
					<Media greaterThanOrEqual="lg">
						<ContentSection type="primary">
							<>
								<Flex
									css={css`
										margin-top: 50px;
									`}
								>
									<FlexItem
										css={css`
											width: 60%;
										`}
									>
										<Typography
											variant="h1"
											color={theme.colors.secondary.header}
										>
											{content.industriesWeServe}
										</Typography>
									</FlexItem>
									<FlexItem>
										<Typography
											variant="p"
											size="sm"
											color={theme.colors.secondary.header}
											css={css`
												margin-left: 50px;
											`}
										>
											{content.industriesWeServeBlurb}
										</Typography>
									</FlexItem>
								</Flex>
							</>
							<div css={css`
                                width: 100%;
                                opacity: 0.1;
                                margin-top: 30px;
                                margin-bottom: 96px;
                                border-bottom: 1px solid ${theme.colors.primary.gray};
                            `}></div>
							{languageContent.industriesServed.map((industry, index) => {
								return (
									<Grid key={index}>
										{index % 2 === 0 ? (
											<Row>
												<Column lg={6}>
                                                    <div
														css={css`
															width: 560px;
															height: 360px;
															margin-bottom: 136px;
															background-image: url(${industry.image
																.file.url});
															background-size: cover;
															background-repeat: no-repeat;
															background-position: center;
														`}
													></div>
												</Column>
												<Column lg={6}>
													<div
														css={css`
															width: 75%;
															margin: auto;
															padding-top: 54px;
														`}
													>
														<Typography
															variant="h2"
															color={theme.colors.primary.header}
															css={css`
																padding-bottom: 70px;
															`}
														>
															{industry.title}
														</Typography>
														<Typography
															variant="p"
															color={theme.colors.primary.text}
														>
															{industry.blurb.blurb}
														</Typography>
													</div>
												</Column>
                                                <div css={css`
                                                    padding-bottom: 70px;
                                                `}>
                                                </div>
											</Row>
										) : (
											<Row>
												<Column lg={6} gutter={0}>
													<div
														css={css`
															height: 602px;
															background-color: ${theme.colors.primary
																.darkerGray};
															margin-bottom: 126px;
														`}
													>
														<div
															css={css`
																width: 75%;
																margin: auto;
																padding-top: 90px;
															`}
														>
															<Typography
																variant="h2"
																color={theme.colors.primary.white}
																css={css`
																	padding-bottom: 70px;
																`}
															>
																{industry.title}
															</Typography>
															<Typography
																variant="p"
																color={theme.colors.primary.white}
																css={css`
																	width: 58%;
																`}
															>
																{industry.blurb.blurb}
															</Typography>
														</div>
													</div>
												</Column>
												<Column lg={6} gutter={0}>
													<div
														css={css`
															width: 100%;
															height: 741px;
															margin-bottom: 120px;
															background-image: url(${industry.image
																.file.url});
															background-size: cover;
															background-repeat: no-repeat;
															background-position: center;
														`}
													></div>
												</Column>
											</Row>
										)}
									</Grid>
								);
							})}
						</ContentSection>
					</Media>
				</>
			) : (
				<></>
			)}
			{/* CONTACT US */}
			<ContactUsRefactor oneButtonVariant={false} withImage={false} background="secondary" />
			{/* LOCATIONS DESKTOP*/}
			<Media greaterThanOrEqual="lg">
				<ContentSection type="secondary">
					<div
						css={css`
							width: 100%;
							background-color: ${theme.colors.primary.white};
						`}
					>
						<div
							css={css`
								padding: 40px;
							`}
						>
							<Typography variant="h3" color={theme.colors.primary.header}>
								{content.locations}
							</Typography>
							<DividerSecondary theme={theme} />
							{languageContent.locations.map((location, index) => {
								let link =
									"/locations/" +
									String(location.country).toLowerCase().replace(" ", "-") +
									"/";
								if (location.country && location.country !== "Mexico") {
									link +=
										String(location.state).toLowerCase().replace(" ", "-") +
										"/" +
										location.slug;
								} else {
									link += location.slug;
								}
								return (
									<div
										key={index}
										css={css`
											border-bottom: 1px solid
												${theme.colors.primary.lighterGray};
											:last-of-type {
												border-bottom: none;
											}
											margin-bottom: 20px;
										`}
									>
										<Grid>
											<Row>
												<Column lg={3} gutter={10}>
													<div
														css={css`
															padding-right: 20px;
														`}
													>
														<Typography
															variant="p"
															size="md"
															weight="bold"
															color={theme.colors.primary.header}
															css={css`
																margin-top: 0px;
															`}
														>
															{location.locationName}
														</Typography>
														<Typography
															variant="p"
															size="sm"
															color={theme.colors.primary.darkGray}
														>
															<Typography
																variant="span"
																css={css`
																	display: block;
																`}
															>
																{location.streetAddress}
															</Typography>
															<Typography
																variant="span"
																css={css`
																	display: block;
																`}
															>
																{location.city +
																	" " +
																	formatState(
																		location.country,
																		location.state
																	) +
																	" " +
																	location.zipCode}
															</Typography>
															<Typography
																variant="span"
																css={css`
																	display: block;
																`}
															>
																{FormatPhoneNumber(
																	location.phoneNumber
																)}
															</Typography>
														</Typography>
														<div
															css={css`
																margin-top: 20px;
															`}
														/>
														<ButtonLink
															label={content.locationViewDetails}
															to={link}
															size="sm"
														/>
													</div>
												</Column>
												<Column lg={3} gutter={10}>
													<HoursDaysSection>
														<Typography
															variant="div"
															size="md"
															color={theme.colors.primary.darkGray}
															css={css`
																margin-bottom: 5px;
															`}
														>
															Mon
														</Typography>
														<Typography
															variant="div"
															size="md"
															color={theme.colors.primary.darkGray}
															css={css`
																margin-bottom: 5px;
															`}
														>
															Tue
														</Typography>
														<Typography
															variant="div"
															size="md"
															color={theme.colors.primary.darkGray}
															css={css`
																margin-bottom: 5px;
															`}
														>
															Wed
														</Typography>
														<Typography
															variant="div"
															size="md"
															color={theme.colors.primary.darkGray}
															css={css`
																margin-bottom: 5px;
															`}
														>
															Thu
														</Typography>
														<Typography
															variant="div"
															size="md"
															color={theme.colors.primary.darkGray}
															css={css`
																margin-bottom: 5px;
															`}
														>
															Fri
														</Typography>
													</HoursDaysSection>
													<HoursHoursSection>
														<Typography
															variant="div"
															color={
																theme.colors.primary.secondaryBrand
															}
															size="md"
															css={css`
																margin-bottom: 5px;
															`}
														>
															{location.locationHours &&
															location.locationHours.mondayOpen &&
															location.locationHours.mondayClose
																? location.locationHours
																		.mondayOpen +
																  " - " +
																  location.locationHours.mondayClose
																: "--"}
														</Typography>
														<Typography
															variant="div"
															color={
																theme.colors.primary.secondaryBrand
															}
															size="md"
															css={css`
																margin-bottom: 5px;
															`}
														>
															{location.locationHours &&
															location.locationHours.tuesdayOpen &&
															location.locationHours.tuesdayClose
																? location.locationHours
																		.tuesdayOpen +
																  " - " +
																  location.locationHours
																		.tuesdayClose
																: "--"}
														</Typography>
														<Typography
															variant="div"
															color={
																theme.colors.primary.secondaryBrand
															}
															size="md"
															css={css`
																margin-bottom: 5px;
															`}
														>
															{location.locationHours &&
															location.locationHours.wednesdayOpen &&
															location.locationHours.wednesdayClose
																? location.locationHours
																		.wednesdayOpen +
																  " - " +
																  location.locationHours
																		.wednesdayClose
																: "--"}
														</Typography>
														<Typography
															variant="div"
															color={
																theme.colors.primary.secondaryBrand
															}
															size="md"
															css={css`
																margin-bottom: 5px;
															`}
														>
															{location.locationHours &&
															location.locationHours.thursdayOpen &&
															location.locationHours.thursdayClose
																? location.locationHours
																		.thursdayOpen +
																  " - " +
																  location.locationHours
																		.thursdayClose
																: "--"}
														</Typography>
														<Typography
															variant="div"
															color={
																theme.colors.primary.secondaryBrand
															}
															size="md"
															css={css`
																margin-bottom: 5px;
															`}
														>
															{location.locationHours &&
															location.locationHours.fridayOpen &&
															location.locationHours.fridayClose
																? location.locationHours
																		.fridayOpen +
																  " - " +
																  location.locationHours.fridayClose
																: "--"}
														</Typography>
													</HoursHoursSection>
												</Column>
												<Column lg={3} gutter={10}>
													<img
														src={location.locationPicture.file.url}
														css={css`
															width: 100%;
															height: 155px;
															margin-bottom: 10px;
														`}
													/>
												</Column>
												<Column lg={3} gutter={10}>
													<div
														css={css`
															width: 100%;
															height: 155px;
														`}
													>
														<Map
															googleKey={
																process.env
																	.GATSBY_GOOGLE_MAPS_KEY ?? ""
															}
															mapData={[
																{
																	location: {
																		lat: location.location.lat,
																		lng: location.location.lon,
																	},
																	name: location.streetAddress,
																	id: location.streetAddress,
																},
															]}
															zoomLevel={11}
														></Map>
													</div>
												</Column>
											</Row>
										</Grid>
									</div>
								);
							})}
						</div>
					</div>
					<div
						css={css`
							margin-bottom: 80px;
						`}
					/>
				</ContentSection>
			</Media>
            {/* LOCATIONS MOBILE */}
			<Media lessThan="lg">
				<ContentSection>
					<Accordion multiple={true}>
						<AccordionTab
							header={content.locations}
							circled={true}
							multiple={true}
							maxHeight={"5000px"}
						>
							<>
								{languageContent.locations.map((location, index) => {
									let link =
										"/locations/" +
										String(location.country).toLowerCase().replace(" ", "-") +
										"/";
									if (location.country && location.country !== "Mexico") {
										link +=
											String(location.state).toLowerCase().replace(" ", "-") +
											"/" +
											location.slug;
									} else {
										link += location.slug;
									}
									return (
										<div
											key={index}
											css={css`
                                                    margin-top:20px;
                                                    padding-bottom: 35px;
                                                    border-bottom: 1px solid ${theme.colors.primary.primaryBrand};
                                                    :last-of-type {
                                                        border-bottom: none;
                                                    }
                                            }`}
										>
											<Typography
												variant="p"
												size="md"
												weight="bold"
												color={theme.colors.primary.header}
												css={css`
													margin-top: 0px;
												`}
											>
												{location.locationName}
											</Typography>
											<Typography
												variant="p"
												size="sm"
												color={theme.colors.primary.darkGray}
											>
												<Typography
													variant="span"
													css={css`
														display: block;
													`}
												>
													{location.streetAddress}
												</Typography>
												<Typography
													variant="span"
													css={css`
														display: block;
													`}
												>
													{location.city +
														" " +
														formatState(
															location.country,
															location.state
														) +
														" " +
														location.zipCode}
												</Typography>
												<Typography
													variant="span"
													css={css`
														display: block;
													`}
												>
													{FormatPhoneNumber(location.phoneNumber)}
												</Typography>
											</Typography>
											<div
												css={css`
													margin-top: 20px;
													margin-bottom: 20px;
												`}
											>
												<ButtonLink
													label={content.locationViewDetails}
													to={link}
													size="sm"
												/>
											</div>

											<HoursDaysSection>
												<Typography
													variant="div"
													size="md"
													color={theme.colors.primary.darkGray}
													css={css`
														margin-bottom: 5px;
													`}
												>
													Mon
												</Typography>
												<Typography
													variant="div"
													size="md"
													color={theme.colors.primary.darkGray}
													css={css`
														margin-bottom: 5px;
													`}
												>
													Tue
												</Typography>
												<Typography
													variant="div"
													size="md"
													color={theme.colors.primary.darkGray}
													css={css`
														margin-bottom: 5px;
													`}
												>
													Wed
												</Typography>
												<Typography
													variant="div"
													size="md"
													color={theme.colors.primary.darkGray}
													css={css`
														margin-bottom: 5px;
													`}
												>
													Thu
												</Typography>
												<Typography
													variant="div"
													size="md"
													color={theme.colors.primary.darkGray}
													css={css`
														margin-bottom: 5px;
													`}
												>
													Fri
												</Typography>
											</HoursDaysSection>
											<HoursHoursSection>
												<Typography
													variant="div"
													color={theme.colors.primary.secondaryBrand}
													size="md"
													css={css`
														margin-bottom: 5px;
													`}
												>
													{location.locationHours &&
													location.locationHours.mondayOpen &&
													location.locationHours.mondayClose
														? location.locationHours.mondayOpen +
														  " - " +
														  location.locationHours.mondayClose
														: "--"}
												</Typography>
												<Typography
													variant="div"
													color={theme.colors.primary.secondaryBrand}
													size="md"
													css={css`
														margin-bottom: 5px;
													`}
												>
													{location.locationHours &&
													location.locationHours.tuesdayOpen &&
													location.locationHours.tuesdayClose
														? location.locationHours.tuesdayOpen +
														  " - " +
														  location.locationHours.tuesdayClose
														: "--"}
												</Typography>
												<Typography
													variant="div"
													color={theme.colors.primary.secondaryBrand}
													size="md"
													css={css`
														margin-bottom: 5px;
													`}
												>
													{location.locationHours &&
													location.locationHours.wednesdayOpen &&
													location.locationHours.wednesdayClose
														? location.locationHours.wednesdayOpen +
														  " - " +
														  location.locationHours.wednesdayClose
														: "--"}
												</Typography>
												<Typography
													variant="div"
													color={theme.colors.primary.secondaryBrand}
													size="md"
													css={css`
														margin-bottom: 5px;
													`}
												>
													{location.locationHours &&
													location.locationHours.thursdayOpen &&
													location.locationHours.thursdayClose
														? location.locationHours.thursdayOpen +
														  " - " +
														  location.locationHours.thursdayClose
														: "--"}
												</Typography>
												<Typography
													variant="div"
													color={theme.colors.primary.secondaryBrand}
													size="md"
													css={css`
														margin-bottom: 5px;
													`}
												>
													{location.locationHours &&
													location.locationHours.fridayOpen &&
													location.locationHours.fridayClose
														? location.locationHours.fridayOpen +
														  " - " +
														  location.locationHours.fridayClose
														: "--"}
												</Typography>
											</HoursHoursSection>
											<img
												src={location.locationPicture.file.url}
												css={css`
													width: 100%;
													height: 189px;
													margin-top: 30px;
													margin-bottom: 15px;
												`}
											/>
											<div
												css={css`
													width: 100%;
													height: 189px;
												`}
											>
												<Map
													googleKey={
														process.env.GATSBY_GOOGLE_MAPS_KEY ?? ""
													}
													mapData={[
														{
															location: {
																lat: location.location.lat,
																lng: location.location.lon,
															},
															name: location.streetAddress,
															id: location.streetAddress,
														},
													]}
													zoomLevel={11}
												></Map>
											</div>
										</div>
									);
								})}
							</>
						</AccordionTab>
					</Accordion>
				</ContentSection>
			</Media>
		</>
	);
};

export default Company;
